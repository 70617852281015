import { DisplayType } from '@shared/types/enums';
import { ThemeGridColumnWidth, ThemeWidthProp } from '@ui-v2/types/layout';
import { ThemeSpacing } from '@ui-v2/types/props';

export const legacyBreakpoints = {
  [DisplayType.ExtraExtraSmall]: 320,
  [DisplayType.ExtraSmall]: 480,
  [DisplayType.Small]: 768,
  [DisplayType.Medium]: 960,
  [DisplayType.Large]: 1024,
  [DisplayType.ExtraLarge]: 1280,
  [DisplayType.ExtraExtraLarge]: 1420,
};

export type Breakpoint = 480 | 768 | 960 | 1024 | 1280 | 1420;

export const breakpoints: [
  Breakpoint,
  Breakpoint,
  Breakpoint,
  Breakpoint,
  Breakpoint,
  Breakpoint,
] = [480, 768, 960, 1024, 1280, 1420];

export const breakpointsMap: Record<Breakpoint, Breakpoint> =
  breakpoints.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: curr,
    }),
    {} as Record<Breakpoint, Breakpoint>,
  );

export const gridColumnWidths: ThemeGridColumnWidth = {
  '0': 0,
  '100%': '100%',
  '1/2': `${(1 / 2) * 100}%`,
  '1/3': `${(1 / 3) * 100}%`,
  '2/3': `${(2 / 3) * 100}%`,
  '1/4': `${(1 / 4) * 100}%`,
  '2/4': `${(2 / 4) * 100}%`,
  '3/4': `${(3 / 4) * 100}%`,
  '1/12': `${(1 / 12) * 100}%`,
  '2/12': `${(2 / 12) * 100}%`,
  '3/12': `${(3 / 12) * 100}%`,
  '4/12': `${(4 / 12) * 100}%`,
  '5/12': `${(5 / 12) * 100}%`,
  '6/12': `${(6 / 12) * 100}%`,
  '7/12': `${(7 / 12) * 100}%`,
  '8/12': `${(8 / 12) * 100}%`,
  '9/12': `${(9 / 12) * 100}%`,
  '10/12': `${(10 / 12) * 100}%`,
  '11/12': `${(11 / 12) * 100}%`,
  '12/12': `${(12 / 12) * 100}%`,
  '1/24': `${(1 / 24) * 100}%`,
  '2/24': `${(2 / 24) * 100}%`,
  '3/24': `${(3 / 24) * 100}%`,
  '4/24': `${(4 / 24) * 100}%`,
  '5/24': `${(5 / 24) * 100}%`,
  '6/24': `${(6 / 24) * 100}%`,
  '7/24': `${(7 / 24) * 100}%`,
  '8/24': `${(8 / 24) * 100}%`,
  '9/24': `${(9 / 24) * 100}%`,
  '10/24': `${(10 / 24) * 100}%`,
  '11/24': `${(11 / 24) * 100}%`,
  '12/24': `${(12 / 24) * 100}%`,
  '13/24': `${(13 / 24) * 100}%`,
  '14/24': `${(14 / 24) * 100}%`,
  '15/24': `${(15 / 24) * 100}%`,
  '16/24': `${(16 / 24) * 100}%`,
  '17/24': `${(17 / 24) * 100}%`,
  '18/24': `${(18 / 24) * 100}%`,
  '19/24': `${(19 / 24) * 100}%`,
  '20/24': `${(20 / 24) * 100}%`,
  '21/24': `${(21 / 24) * 100}%`,
  '22/24': `${(22 / 24) * 100}%`,
  '23/24': `${(23 / 24) * 100}%`,
  '24/24': `${(24 / 24) * 100}%`,
};

export const sizes: ThemeWidthProp = {
  ...gridColumnWidths,
  full: '100%',
  container: 1280,
  viewportWidth: '100vw',
  auto: 'auto',
};

export const legacySpacings = {
  [DisplayType.ExtraExtraSmall]: 4,
  [DisplayType.ExtraSmall]: 8,
  [DisplayType.Small]: 16,
  [DisplayType.Medium]: 20,
  [DisplayType.Large]: 24,
  [DisplayType.ExtraLarge]: 36,
  [DisplayType.ExtraExtraLarge]: 48,
};

export const spacings: Record<ThemeSpacing, ThemeSpacing> = {
  '-24': -24,
  '-16': -16,
  '-8': -8,
  '-4': -4,
  '-2': -2,
  '0': 0,
  '2': 2,
  '4': 4,
  '8': 8,
  '12': 12,
  '16': 16,
  '20': 20,
  '24': 24,
  '32': 32,
  '40': 40,
  '48': 48,
  '56': 56,
  '64': 64,
  '72': 72,
  '80': 80,
  auto: 'auto',
};
